<template lang="pug">
  jumbotron(title="" small)
    template(v-slot:content)
      makePayout(:items="activeItems" @needToUpdate="$emit('needToUpdate')")
        template(v-slot:activator="makePayout")
          app-button(
            color="primary"
            :disabled="isDisabled || !canAddPayoutInDrivingSchool"
            @click.native="makePayout.toggle"
            class="make-button"
          ) Mark as paid
</template>

<script>
import permsMixin from '@/mixins/perms.mixin'
import appSelect from '@/components/global/fields/appSelect.vue'
import appButton from '@/components/global/actions/BaseButton.vue'
import ROUTE_NAMES from '@/app/admin/router/router-const'

export default {
  name: 'DrivingSchoolActions',

  mixins: [permsMixin],

  props: {
    loading: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    activeItems: {type: Array, required: true}
  },

  computed: {
    isListPage() {
      return this.$route.name === ROUTE_NAMES.DRIVING_SCHOOLS
    },

    isApplicationsPage() {
      return this.$route.name === ROUTE_NAMES.DRIVING_SCHOOLS_APPLICATIONS
    },

    isTransactionsPage() {
      return this.$route.name === ROUTE_NAMES.DRIVING_SCHOOLS_TRANSACTIONS
    },

    isDisabled() {
      return this.loading || this.disabled;
    }
  },

  components: {
    appSelect,
    appButton,
    jumbotron: () => import('@/components/global/fields/Jumbotron.vue'),
    makePayout: () => import('./applications-page/MakePayout.vue')
  }
}
</script>

<style lang="scss">
.make-button {
  text-transform: uppercase;
}
</style>
